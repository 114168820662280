import React from 'react';
import { graphql } from 'gatsby';
import logger from 'utils/logger';

// import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

// import { Container, Row } from 'components/GridSystem';
import {
  CoverSoon
} from 'components/Sections';

class Page extends React.Component {
  render () {
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    const siteTitle = data.site.siteMetadata.title;
    const {
      title,
      fields,
      featuredImage
    } = data.contentfulPage;
    const { blocks } = fields;
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="THINKLO" content={blocks[0].content.childMdx.excerpt} />
        <article>
          <CoverSoon
            bgColor={`gray-800`}
            label={`capabilty`}
            title={title}
            content={`heading && heading.body`}
            blocks={blocks}
            background={{
              image: featuredImage && featuredImage.cover.fixed.src,
              video: featuredImage && (featuredImage.videoLoop && featuredImage.videoLoop.file.url),
            }}
          />
        </article>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      ...Page
    }
  }
`;
